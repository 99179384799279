import React, { useState } from "react";
import axios from "axios";

const AgendarChamada = () => {
  const [dataHora, setDataHora] = useState("");
  const [nomeLaudo, setNomeLaudo] = useState(""); // Estado para o nome do laudo
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const agendarChamada = async () => {
    setLoading(true); // Inicia o estado de carregamento
    try {
      const res = await axios.post(
        "http://89.116.214.151:3000/IA/v1/agendarChamada",
        {
          horario: dataHora,
          nomeLaudo: nomeLaudo, // Enviar o nome do laudo
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setResponse(JSON.stringify(res.data, null, 2));
    } catch (error) {
      setResponse(
        JSON.stringify(
          error.response ? error.response.data : error.message,
          null,
          2
        )
      );
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Agendar Chamada</h2>

      {/* Input para inserir o nome do laudo */}
      <input
        type="text"
        value={nomeLaudo}
        onChange={(e) => setNomeLaudo(e.target.value)}
        placeholder="Nome do Laudo"
        className="input"
      />

      {/* Input para selecionar a data e hora */}
      <input
        type="datetime-local"
        value={dataHora}
        onChange={(e) => setDataHora(e.target.value)}
        className="input"
      />

      {/* Botão para agendar a chamada */}
      <button onClick={agendarChamada} disabled={loading}>
        {loading ? "Agendando..." : "Agendar Chamada"}
      </button>

      {/* Exibir a resposta da API */}
      <div className="response">{response}</div>
    </section>
  );
};

export default AgendarChamada;
