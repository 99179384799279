import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000/v1";

function ListarImagens() {
  const [imagens, setImagens] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(""); // Estado de erro

  const listarImagens = async () => {
    setLoading(true); // Inicia o estado de carregamento
    setError(""); // Limpa o erro antes de nova requisição
    try {
      const res = await axios.get(`${baseURL}/listarImagens`);
      setImagens(res.data.imagens || []); // Atualiza o estado com a lista de imagens ou um array vazio
    } catch (error) {
      setError("Erro ao listar imagens.");
      setImagens([]); // Limpa a lista de imagens em caso de erro
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Listar Imagens do Servidor</h2>
      <button onClick={listarImagens} disabled={loading}>
        {loading ? "Carregando..." : "Listar Imagens"}
      </button>
      {loading && <p>Carregando imagens...</p>}
      {error && <p>{error}</p>}
      <div className="image-gallery">
        {imagens.length > 0
          ? imagens.map((imagem, index) => (
              <div key={index} className="image-item">
                <img src={imagem.thumbnail} alt={imagem.nome} width="100" />
                <p>{imagem.nome}</p>
              </div>
            ))
          : !loading && <p>Nenhuma imagem encontrada.</p>}
      </div>
    </section>
  );
}

export default ListarImagens;
