import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000/v1";

function LerEmailTo() {
  const [emailTo, setEmailTo] = useState("");
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const lerEmailTo = async () => {
    setLoading(true); // Inicia o estado de carregamento
    try {
      const res = await axios.get(`${baseURL}/verEmailTo`);
      setEmailTo(res.data.EMAIL_TO || "Email não encontrado"); // Atualiza com o email recebido
    } catch (error) {
      setEmailTo(error.response?.data?.message || "Erro ao ler o EMAIL_TO");
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Visualizar email de destino dos agendamentos cadastrado</h2>
      <button onClick={lerEmailTo} disabled={loading}>
        {loading ? "Carregando..." : "Visualizar Email"}
      </button>
      <div className="response">
        {loading ? (
          <p>Carregando email...</p>
        ) : (
          emailTo && <p>EMAIL: {emailTo}</p>
        )}
      </div>
    </section>
  );
}

export default LerEmailTo;
