import React, { useState } from "react";
import axios from "axios";

const DeletarImagensProcessadas = () => {
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const deletarImagens = async () => {
    setLoading(true); // Inicia o estado de carregamento
    try {
      const res = await axios.delete(
        "http://89.116.214.151:3000/v1/deletarImagensProcessadas"
      );
      setResponse(JSON.stringify(res.data, null, 2));
      setError(""); // Limpa o erro, se houver
    } catch (err) {
      setError(
        JSON.stringify(err.response ? err.response.data : err.message, null, 2)
      );
      setResponse(""); // Limpa a resposta, se houver erro
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Deletar Imagens Processadas</h2>
      <button onClick={deletarImagens} disabled={loading}>
        {loading ? "Deletando..." : "Deletar Imagens Processadas"}
      </button>
      <div className="response">
        {response && <pre>{response}</pre>}
        {error && <pre>{error}</pre>}
      </div>
    </section>
  );
};

export default DeletarImagensProcessadas;
