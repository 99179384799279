import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000";

function BuscarDescricoesDasImagens() {
  const [descricoes, setDescricoes] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const buscarDescricoesDasImagens = async () => {
    setLoading(true); // Inicia o estado de carregamento
    try {
      const res = await axios.get(
        `${baseURL}/IA/v1/buscarDescricoesDasImagens`
      );
      setDescricoes(res.data.contentArray); // Atualiza o estado com o array de descrições
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      setDescricoes([`Erro ao buscar descrições: ${errorMessage}`]);
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  const copiarResposta = () => {
    const respostaFormatada = descricoes.join("\n"); // Converte o array para uma string com quebras de linha
    if (respostaFormatada) {
      navigator.clipboard
        .writeText(respostaFormatada)
        .then(() => {
          alert("Resposta copiada para a área de transferência!");
        })
        .catch((err) => {
          console.error("Erro ao copiar para a área de transferência: ", err);
        });
    }
  };

  return (
    <section className="highlight">
      <h2>Buscar Descrições das Imagens</h2>
      <button onClick={buscarDescricoesDasImagens} disabled={loading}>
        {loading ? "Carregando..." : "Buscar Descrições"}
      </button>
      <button
        onClick={copiarResposta}
        style={{ marginLeft: "10px" }}
        disabled={descricoes.length === 0}
      >
        Copiar
      </button>
      <div className="response">
        {loading ? (
          <p>Carregando descrições...</p>
        ) : descricoes.length > 0 ? (
          descricoes.map((descricao, index) => (
            <p key={index}>{descricao}</p> // Exibe cada descrição em um parágrafo
          ))
        ) : (
          <p>Nenhuma descrição disponível.</p>
        )}
      </div>
    </section>
  );
}

export default BuscarDescricoesDasImagens;
