import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000/v1";

function EnviarImagens() {
  const [response, setResponse] = useState("");
  const [nomeLaudo, setNomeLaudo] = useState(""); // Estado para armazenar o nome do laudo
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const enviarImagens = async (event) => {
    const files = event.target.files;
    const formData = new FormData();

    // Adicionar o nome do laudo ao FormData
    formData.append("nomeLaudo", nomeLaudo);

    // Adicionar as imagens ao FormData
    for (let i = 0; i < files.length; i++) {
      formData.append("imagens", files[i]);
    }

    setLoading(true); // Inicia o estado de carregamento

    try {
      const res = await axios.post(`${baseURL}/enviarImagens`, formData);
      setResponse(JSON.stringify(res.data, null, 2));
    } catch (error) {
      setResponse(
        JSON.stringify(
          error.response ? error.response.data : error.message,
          null,
          2
        )
      );
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Enviar Imagens</h2>

      {/* Campo para inserir o nome do laudo */}
      <input
        type="text"
        value={nomeLaudo}
        onChange={(e) => setNomeLaudo(e.target.value)}
        placeholder="Nome do Laudo"
        disabled={loading} // Desativa o campo enquanto carrega
      />

      {/* Input para enviar as imagens */}
      <input type="file" multiple onChange={enviarImagens} disabled={loading} />

      {/* Exibir a resposta da API */}
      <div className="response">
        {loading ? <p>Enviando imagens...</p> : response}
      </div>
    </section>
  );
}

export default EnviarImagens;
