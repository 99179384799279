import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000/v1";

function LerApiKey() {
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const lerApiKey = async () => {
    setLoading(true); // Inicia o estado de carregamento
    try {
      const res = await axios.get(`${baseURL}/lerApiKey`);
      setApiKey(res.data.apiKey || "Chave não encontrada"); // Atualiza com a chave recebida
    } catch (error) {
      setApiKey(error.response?.data?.message || "Erro ao ler a API Key");
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Ler API Key</h2>
      <button onClick={lerApiKey} disabled={loading}>
        {loading ? "Carregando..." : "Ler API Key"}
      </button>
      <div className="response">
        {loading ? (
          <p>Carregando chave...</p>
        ) : (
          apiKey && <p>API Key: {apiKey}</p>
        )}
      </div>
    </section>
  );
}

export default LerApiKey;
