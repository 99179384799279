import React from "react";
import "./styles.css"; // Certifique-se de que está usando './' e não um caminho absoluto
import EnviarImagens from "./components/EnviarImagens";
import ListarImagens from "./components/ListarImagens";
import DeletarImagens from "./components/DeletarImagens";
import LerArquivo from "./components/LerArquivo";
import AtualizarArquivo from "./components/AtualizarArquivo";
import BuscarDescricoesDasImagens from "./components/BuscarDescricoesDasImagens";
import AgendarChamada from "./components/AgendarChamada";
import AgendamentosAtivos from "./components/AgendamentosAtivos";
import AgendamentosConcluidos from "./components/AgendamentosConcluidos";
import RemoverAgendamento from "./components/RemoverAgendamento";
import LerRespostas200OK from "./components/LerRespostas200OK";
import DeletarRespostas from "./components/DeletarRespostas200OK";
import ListarImagensProcessadas from "./components/ListarImagensProcessadas";
import DeletarImagensProcessadas from "./components/DeletarImagensProcessadas";
import AtualizarApiKey from "./components/atualizarKey";
import LerApiKey from "./components/lerKey";
import LerEmailTo from "./components/lerEmailAtual";
import AtualizarEmailTo from "./components/visualizarEmailAtual";

function App() {
  return (
    <div className="container">
      <h1>API Integration Dashboard</h1>
      <p>
        Todos os botões, excluindo o botão <b>BUSCAR DESCRIÇÕES</b> se referem a
        API de manipulação de arquivos do servidor, essas opções não
        chamam/requisitam a API da OPEN.AI para nada, consequentemente não
        envolve gasto monetário nessas operações, a unica operação que faz uma
        leitura dos arquivos disponiveis do servidor e com base neles chama a
        API da OPEN.AI, é a opção <b>BUSCAR DESCRIÇÕES</b>
      </p>
      <br />
      <p>
        Uma vez que qualquer um dos botões seja clicado, caso não funcione sera
        retornado erro, do contrário é entendido que o processo funcionou, a
        ideia é que uma opção complemente a outra, exemplo: É possivel ENVIAR
        IMAGENS, também é possível LISTAR IMAGENS, se a resposta de LISTAR
        IMAGENS se refere as imagens enviadas anteriormente, é entendido que as
        imagens foram enviadas com sucesso, o mesmo se aplica a todas as opções.
      </p>
      <EnviarImagens />
      <DeletarImagens />
      <BuscarDescricoesDasImagens />
      <ListarImagens />
      <LerArquivo />
      <AtualizarArquivo />
      <AgendarChamada />
      <AgendamentosAtivos />
      <AgendamentosConcluidos />
      <RemoverAgendamento />
      <LerRespostas200OK />
      <DeletarRespostas />
      <ListarImagensProcessadas />
      <DeletarImagensProcessadas />
      <AtualizarApiKey />
      <LerApiKey />
      <LerEmailTo />
      <AtualizarEmailTo />
    </div>
  );
}

export default App;
