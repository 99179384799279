import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000/v1";

function AtualizarApiKey() {
  const [apiKey, setApiKey] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const atualizarApiKey = async () => {
    setLoading(true); // Inicia o estado de carregamento
    try {
      const res = await axios.post(`${baseURL}/atualizarApiKey`, {
        key: apiKey,
      });
      setResponseMessage(res.data.message); // Exibe a mensagem de sucesso
    } catch (error) {
      console.error("Erro ao atualizar a API Key:", error);
      setResponseMessage(
        error.response?.data?.message || "Erro ao atualizar a API Key"
      );
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Atualizar API Key</h2>
      <input
        type="text"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        placeholder="Digite a nova API Key"
        disabled={loading} // Desativa enquanto está carregando
      />
      <button onClick={atualizarApiKey} disabled={loading}>
        {loading ? "Atualizando..." : "Atualizar API Key"}
      </button>
      {responseMessage && <p>{responseMessage}</p>}
    </section>
  );
}

export default AtualizarApiKey;
