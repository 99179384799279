import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000/v1";

function AtualizarEmailTo() {
  const [newEmailTo, setNewEmailTo] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(""); // Estado para erros

  const atualizarEmailTo = async () => {
    setLoading(true); // Inicia o estado de carregamento
    setError(""); // Limpa o erro antes da nova requisição
    setResponseMessage(""); // Limpa a resposta antes da nova requisição

    try {
      const res = await axios.post(`${baseURL}/atualizarEmailTo`, {
        newEmailTo: newEmailTo,
      });
      setResponseMessage(res.data.message); // Exibe a mensagem de sucesso
    } catch (error) {
      console.error("Erro ao atualizar o EMAIL_TO:", error);
      setError(error.response?.data?.message || "Erro ao atualizar o EMAIL_TO");
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Atualizar E-mail de envio de agendamentos</h2>
      <input
        type="email"
        value={newEmailTo}
        onChange={(e) => setNewEmailTo(e.target.value)}
        placeholder="Digite o novo EMAIL"
      />
      <button onClick={atualizarEmailTo} disabled={loading}>
        {loading ? "Atualizando..." : "Atualizar Email"}
      </button>
      {loading && <p>Atualizando o email...</p>}
      {responseMessage && <p>{responseMessage}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </section>
  );
}

export default AtualizarEmailTo;
