import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000/v1";

function DeletarImagens() {
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const deletarImagens = async () => {
    setLoading(true); // Inicia o estado de carregamento
    try {
      const res = await axios.delete(`${baseURL}/deletarImagens`);
      setResponse(JSON.stringify(res.data, null, 2));
    } catch (error) {
      setResponse(
        JSON.stringify(
          error.response ? error.response.data : error.message,
          null,
          2
        )
      );
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Deletar Imagens</h2>
      <button onClick={deletarImagens} disabled={loading}>
        {loading ? "Deletando..." : "Deletar Imagens"}
      </button>
      <div className="response">{response}</div>
    </section>
  );
}

export default DeletarImagens;
