import React, { useState } from "react";
import axios from "axios";

const LerRespostas200OK = () => {
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const lerDescricoes = async () => {
    setLoading(true); // Inicia o estado de carregamento
    try {
      const res = await axios.get(
        "http://89.116.214.151:3000/v1/lerRespostas200Ok"
      );
      setResponse(JSON.stringify(res.data, null, 2));
      setError(""); // Limpa o erro, se houver
    } catch (err) {
      setError(
        JSON.stringify(err.response ? err.response.data : err.message, null, 2)
      );
      setResponse(""); // Limpa a resposta, se houver erro
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Ler Descrições de Imagens</h2>
      <p>Imagens recebidas da IA via agendamento</p>
      <button onClick={lerDescricoes} disabled={loading}>
        {loading ? "Carregando..." : "Ler Descrições de Imagens"}
      </button>
      <div className="response">
        {loading && <p>Carregando descrições...</p>}
        {response && <pre>{response}</pre>}
        {error && <pre>{error}</pre>}
      </div>
    </section>
  );
};

export default LerRespostas200OK;
