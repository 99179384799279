import React, { useState } from "react";
import axios from "axios";

const RemoverAgendamento = () => {
  const [agendamentoId, setAgendamentoId] = useState("");
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const removerAgendamento = async () => {
    setLoading(true); // Inicia o estado de carregamento
    setError(""); // Limpa o erro antes da nova requisição
    setResponse(""); // Limpa a resposta antes da nova requisição
    try {
      const res = await axios.delete(
        `http://89.116.214.151:3000/IA/v1/removerAgendamento/${agendamentoId}`
      );
      setResponse(JSON.stringify(res.data, null, 2));
    } catch (err) {
      setError(
        JSON.stringify(err.response ? err.response.data : err.message, null, 2)
      );
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Remover Agendamento</h2>
      <input
        type="text"
        value={agendamentoId}
        onChange={(e) => setAgendamentoId(e.target.value)}
        placeholder="Digite o ID do agendamento"
        className="input"
      />
      <button onClick={removerAgendamento} disabled={loading}>
        {loading ? "Removendo..." : "Remover Agendamento"}
      </button>
      <div className="response">
        {loading && <p>Processando a remoção...</p>}
        {response && <pre>{response}</pre>}
        {error && <pre>{error}</pre>}
      </div>
    </section>
  );
};

export default RemoverAgendamento;
